import { LazyExoticComponent, lazy } from "react";
import { createRoot } from "react-dom/client";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { browserName } from "react-device-detect";

import { FRONTEND_GAME_SLUG } from "@library/project/constants/frontendGameSlugs";
import { FrontendGameSlugs } from "@library/project/interfaces/FrontendGameSlugs";
import { resetStorageOmitProd } from "@library/frontend/tools/resetStorage";
import i18n from "@library/project/translations/i18n";

import "@library/frontend/scss/reset.scss";
import "@library/frontend/scss/root.scss";
import "@library/frontend/scss/grid.scss";
import "@library/frontend/scss/utils.scss";
import "@library/frontend/scss/typography.scss";
import "@library/frontend/scss/layers.scss";
import "@library/frontend/scss/responsive.scss";
import "@library/frontend/scss/design-system.scss";

import {
  FUN_PLAY_IFRAME_AUTH_KEY_COOKIES_KEY,
  FUN_PLAY_IFRAME_QUERY_STRING_COOKIES_KEY,
  GAME_MODE_STORAGE_KEY,
  JWT_CASINO_COOKIE_KEY,
  REAL_PLAY_IFRAME_AUTH_KEY_COOKIES_KEY,
  REAL_PLAY_IFRAME_QUERY_PARAMS_KEY,
  REAL_PLAY_SESSION_ID_COOKIES_KEY
} from "@library/project/constants/storage/cookieKeys";
import {
  CAN_WITHDRAW_KEY,
  FIRST_TIME_VISIT_KEY,
  IS_ADULT_KEY,
  KNOWS_HOW_TO_PLAY
} from "@library/project/constants/storage/localStorageKeys";

//GAMES
const CoinFlipperGame = lazy(() => import("./app/games/coinFlip/CoinFlipperRouter"));
const LuckyStrikeGame = lazy(() => import("./app/games/luckyStrike/LuckyStrikeRouter"));
const ToTheMoonGame = lazy(() => import("./app/games/toTheMoon/ToTheMoonRouter"));
const MinerGame = lazy(() => import("./app/games/miner/MinerRouter"));
//GAMES

resetStorageOmitProd({
  cookiesKeys: [
    GAME_MODE_STORAGE_KEY,
    JWT_CASINO_COOKIE_KEY,
    REAL_PLAY_IFRAME_AUTH_KEY_COOKIES_KEY,
    REAL_PLAY_IFRAME_QUERY_PARAMS_KEY,
    REAL_PLAY_SESSION_ID_COOKIES_KEY,
    FUN_PLAY_IFRAME_AUTH_KEY_COOKIES_KEY,
    FUN_PLAY_IFRAME_QUERY_STRING_COOKIES_KEY
  ],
  localStorageKeys: [KNOWS_HOW_TO_PLAY, IS_ADULT_KEY, FIRST_TIME_VISIT_KEY],
  sessionStorageKeys: [CAN_WITHDRAW_KEY]
});

const gamePerSlug: Record<FrontendGameSlugs, LazyExoticComponent<() => JSX.Element>> = {
  coinFlip: CoinFlipperGame,
  luckyStrike: LuckyStrikeGame,
  toTheMoon: ToTheMoonGame,
  miner: MinerGame
};

const buildGame = () => {
  const Game = gamePerSlug[FRONTEND_GAME_SLUG];

  if (!Game) throw new Error(`There is no such game defined by REACT_APP_GAME_SLUG env`);

  return <Game />;
};

const rootElement = document.getElementById("root");
export const ReactRoot = createRoot(rootElement!);

ReactRoot.render(
  <HelmetProvider>
    <Helmet htmlAttributes={{ "data-browser": browserName, lang: i18n.language }} />
    {<>{buildGame()}</>}
  </HelmetProvider>
);
